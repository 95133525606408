import React from "react"

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 42 42"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#000">
          <path
            d="M649.408 624c6.393 0 11.592 5.2 11.592 11.59v18.82c0 6.39-5.2 11.59-11.592 11.59h-18.816c-6.393 0-11.592-5.2-11.592-11.59v-18.82c0-6.39 5.2-11.59 11.592-11.59zm0 3.729h-18.816c-4.338 0-7.866 3.527-7.866 7.86v18.821c0 4.336 3.528 7.864 7.866 7.864h18.816c4.338 0 7.866-3.528 7.866-7.864v-18.82c0-4.334-3.528-7.861-7.866-7.861zm-9.408 6.45c5.967 0 10.821 4.854 10.821 10.822 0 5.967-4.854 10.821-10.821 10.821-5.968 0-10.822-4.854-10.822-10.821 0-5.968 4.854-10.822 10.822-10.822zm0 3.726c-3.913 0-7.098 3.183-7.098 7.096 0 3.912 3.185 7.095 7.098 7.095 3.912 0 7.097-3.183 7.097-7.095 0-3.913-3.182-7.096-7.097-7.096zm11.277-6.885c.72 0 1.426.29 1.932.8.507.505.8 1.212.8 1.932 0 .717-.293 1.424-.8 1.934-.508.505-1.213.8-1.932.8-.72 0-1.425-.295-1.93-.8-.51-.51-.804-1.217-.804-1.934 0-.72.294-1.427.804-1.932.505-.51 1.21-.8 1.93-.8z"
            transform="translate(-619 -624)"
          />
        </g>
      </g>
    </svg>
  )
}

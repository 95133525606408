import React from "react"

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
    >
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g stroke="#000">
          <g transform="translate(-801 -478) translate(802 479)">
            <circle cx="11.273" cy="28.182" r="1.409" />
            <circle cx="26.773" cy="28.182" r="1.409" />
            <path d="M0 0h5.636l3.777 18.868c.266 1.339 1.453 2.295 2.818 2.268h13.696c1.365.027 2.553-.93 2.818-2.268L31 7.045H7.045" />
          </g>
        </g>
      </g>
    </svg>
  )
}
